import * as nav from "./modules/navigation.js";
import animateOnScroll from "./modules/observer.js";

// Stop transitions before page has loaded
window.addEventListener(
  "load",
  (event) => {
    document.body.classList.remove("preload");
  },
  false,
);
